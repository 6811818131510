import React from 'react';
import './SocialLinks.css'; // Optional: Add custom styles if needed

function SocialLinks() {
  return (
    <div className="social-links">
      <a href="https://www.linkedin.com/in/tbfaux/" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-linkedin"></i>
      </a>
      <a href="https://x.com/tbfaux" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-twitter"></i>
      </a>
      <a href="https://www.instagram.com/tbfaux/" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-instagram"></i>
      </a>
    </div>
  );
}

export default SocialLinks; 