import './Cards.css';
import Card from './Card.js';

import whatnotLogo from '../assets/logos/whatnot.png'
import supergreatLogo from '../assets/logos/supergreat.png'
import tictailLogo from '../assets/logos/tictail.png'
import harvardLogo from '../assets/logos/harvard.png'
import nycLogo from '../assets/logos/nyc.png'

const regularCards = [
    {
        logo: whatnotLogo,
        years: '2023 – Present',
        location: 'Los Angeles',
        bgColor: '#000000',
        fgColor: 'white',
        description: 'Working on the Product team at Whatnot, the largest livestream platform and fastest growing marketplace in the US.\n\nFocused on social commerce experiences to help our sellers turn their passion into a business.'
    },
    {
        logo: supergreatLogo,
        years: '2018 – 2023',
        location: 'NYC & LA',
        fgColor: 'white',
        bgColor: '#2C013C',
        description: 'Co-founder and CEO of Supergreat, a video-first platform for beauty & skincare entrepreneurs and creators to share their favorite products with our community.\n\nAcquired by Whatnot in 2023.'
    },
    {
        logo: tictailLogo,
        years: '2014 – 2016',
        location: 'Stockholm & NYC',
        bgColor: '#FBC011',
        fgColor: 'black',
        description: 'Tictail was an e-commerce store builder and marketplace allowing shoppers to discover emerging designers around the world.\n\nAcquired by Shopify in 2018.'
    },
    {
        logo: harvardLogo,
        years: 'A.B., Computer Science',
        location: 'Cambridge',
        bgColor: '#63111d',
        fgColor: 'white',
        heightOverride: '110px',
        description: 'I studied Computer Science in college and participated in the Hasty Pudding Theatricals, a drag burlesque musical, and the Harvard Lampoon, a comedy magazine.'
    },
    {
        logo: nycLogo,
        years: 'The 90s',
        location: 'NYC',
        bgColor: '#F5F5DC',
        fgColor: 'black',
        description: 'I grew up in the 90s in New York City. It is a great city.\n\nI had fun and formative internships at Tumblr, Goldman Sachs, the TriBeCa Film Festival, and Columbia Medical School.'
    }
]
function Cards() {
  return (
    <div className="Cards-container">
        {regularCards.map((c) => (
            <div key={c.logo} className="Cards-card">
                <Card 
                    {...c}
                />
            </div>
        ))}
    </div>
  );
}

export default Cards;
