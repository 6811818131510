import React from 'react';
import './App.css';
import Cards from './components/Cards';
import SocialLinks from './components/SocialLinks';
function App() {
  return (
    <div className="container">
      <div className="body">
        <Cards style={{margin: 'auto'}} />
      </div>
      <div className="section footer" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 20px'}}>
        <div style={{textAlign: 'left'}}>
          {`👋 Tyler Faux | tbfaux.com`}
        </div>
        <div style={{textAlign: 'right'}}>
          <SocialLinks />
        </div>
      </div>
    </div>
  );
}

export default App;
