import React, {useState} from 'react';
import classNames from 'classnames';
import './Card.css';


function Card({bgColor, fgColor, description,logo, years, heightOverride, location}) {
  const [isHovered, setIsHovered] = useState(false);
  const onMouseOver = () => setIsHovered(true)
  const onMouseOut = () => setIsHovered(false)
  return (
    <div className="Card-container" onMouseEnter={onMouseOver} onMouseLeave={onMouseOut}>
        <div className={classNames("Card-container-bg", {'Card-container-bg-blurred': isHovered, 'Card-container-bg-unblurred': !isHovered})} style={{backgroundColor: bgColor}}>
          <div className={classNames("Card-blur", {'Card-blur-blurred': isHovered, 'Card-blur-unblurred': !isHovered})}>
            <div className="Card-logo">
                <img alt={description} src={logo} style={{height: heightOverride || '90px'}}/>
            </div>
            <div className="Card-space" />
            <div className="Card-text" style={{color: fgColor}}>
                <p className="Card-text-years">{years}</p>
                <p className="Card-text-location">{location}</p>
            </div>
          </div>
        </div>
        <div className={classNames("Card-context", {'Card-context-blurred': isHovered, 'Card-context-unblurred': !isHovered})}>
          <h1>{description}</h1>
        </div>
    </div>
  );
}

export default Card;
